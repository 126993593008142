import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
    width?: number | string
    height?: number | string
  }
  
  export const useStyles = makeStyles({
    a: (props: Props) => ({
      width: props.width || "22px",
      height: props.height || "100%",
      verticalAlign: "middle",
    }),
    b: {
        fill: "#46bde3"
    },
    c: {
        fill: "#fff"
    }
  })

const History: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes.a} viewBox="0 0 30 30">
            <circle className={classes.b} cx="15" cy="15" r="15"/>
            <path className={classes.c} d="M9.664,3a6.477,6.477,0,0,0-6.5,6.455H1l2.809,2.79.051.1,2.917-2.89H4.61A5.061,5.061,0,1,1,6.1,13L5.072,14.016A6.475,6.475,0,1,0,9.664,3ZM8.942,6.586v3.586l3.068,1.807.556-.918-2.541-1.5V6.586Z" transform="translate(6 6)"/>
        </svg>
    )
}
  
  export default History