import React from "react"
import {
  Paper,
  Modal as ReactModal,
  Divider,
  Button,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import styles from "../styles/main.module.css"
import { isMobile } from "react-device-detect"
const useStyles = makeStyles({
  modalHeader: {
    paddingBottom: "15px",
    fontSize: "16px",
  },
  modalBody: {},
  circularProgressWrapper: {
    position: "absolute",
    background: "#fff",
    top: 0,
    left: 0,
    alignItems: "center",
    height: "100%",
    width: "100%",
    borderRadius: "8px",
  },
  circularProgress: {
    margin: "auto",
    display: "block",
    marginTop: "50%",
  },
  modelMobile: {
    padding: "20px!important",
  },
})

const Modal: React.FC<any> = (props: any) => {
  const {
    onClose,
    title,
    loading,
    fullWidth,
    disableBackdropClose = false,
    ...others
  } = props
  const classes = useStyles()

  return (
    <ReactModal {...others} onClose={disableBackdropClose ? null : onClose}>
      <Paper
        className={`${styles.paper} ${styles.modal} ${
          isMobile ? classes.modelMobile : ""
        } ${fullWidth === true ? styles.modalFull : ""}`}
        elevation={3}
      >
        <div className={`${styles.positionRelative} ${classes.modalHeader}`}>
          <strong>{title}</strong>
          <Button className={styles.modalCloseButton} onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <Divider />
        <div className={classes.modalBody}>{props.children}</div>
        {loading ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress className={classes.circularProgress} />
          </div>
        ) : (
          ""
        )}
      </Paper>
    </ReactModal>
  )
}

export default Modal
