import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider as StyledThemeProvider } from "styled-components"

import { ApolloProvider } from "@apollo/client"
import ThemeProvider from "@material-ui/styles/ThemeProvider"

import { getApolloClient } from "./graphql"
import { createStore } from "./state"
import theme from "./theme"

interface Props {
  element?: any
}

const wrapRootElement: React.FC<Props> = ({ element }: Props) => {
  const store = createStore()
  const apolloClient = getApolloClient()

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>{element}</StyledThemeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default wrapRootElement
