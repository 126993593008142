/* eslint-disable @typescript-eslint/camelcase */
import { Auth, Logger } from "aws-amplify"
import { FormikHelpers } from "formik"
import { navigate } from "gatsby"
import React, { useMemo } from "react"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import styles from "../../styles/main.module.css"
import { useNotify } from "../../notifications"
import { toInternationalPhoneNumber } from "../../utils/phonenumber"
import FormContainer from "./FormContainer"
import SignUpForm, { FormValues } from "./SignUpForm"
import { Logo, SEO } from ".."
import styled from "styled-components"
import CompactFooter from "../CompactFooter"
import { useSelector } from "react-redux"
import { RootState } from "../../state/rootReducer"

const SignUpContainer = styled.div`
  padding: 1rem 0;
  width: 700px;
  margin: auto;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  @media (max-width: 960px) {
    padding: 120px 50px 0px 50px;
  }

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

export const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 5rem;

  @media (max-width: 1240px) {
    padding: 0;
    width: 90%;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
`
export const PageContainer = styled.div`
  background-color: #f7f9fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

export const LogoContainer = styled(Container)`
  padding: 45px;
  text-align: center;

  @media (max-width: 1240px) {
    padding: 60px 10px 52px 10px;
    padding: 30px;
  }

  @media (max-width: 1024px) {
    padding: 60px 10px 52px 10px;
  }

  @media (max-width: 600px) {
    img {
      max-width: 280px;
    }
  }

  @media (max-width: 300px) {
    img {
      max-width: 180px;
    }
  }
`

const SignInContainer = styled(Grid)`
  text-align: center;
`

const DescriptionTypography = styled(Typography)`
  word-break: word;
  text-align: center;
`

const SignUpPage: React.FC<{}> = () => {
  const logger = useMemo(() => new Logger(SignUpPage.name), [])
  const notify = useNotify()
  const notifications: any = useSelector<RootState>(
    (state) => state.notifications,
  )

  const handleIdNumberCheck = async (idNumber: string): Promise<boolean> => {
    return fetch(
      `${process.env.OPEN_API_URI}/employees/check/${idNumber}`,
    ).then((response) => {
      return response.status == 200
    })
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const {
      given_name,
      family_name,
      id_number,
      phone_number,
      password,
    } = values

    const isValidIdNumber = await handleIdNumberCheck(id_number)
    if (!isValidIdNumber) {
      notify.error(
        "ID/Passport number doesn't existing in the system, please contact the administrator.",
      )
      actions.setSubmitting(false)
    } else {
      const phoneNumber = toInternationalPhoneNumber(phone_number)
      try {
        const result = await Auth.signUp({
          username: phoneNumber || "",
          password,
          attributes: {
            given_name,
            family_name,
            "custom:id_number": id_number.trim(),
            phone_number: phoneNumber?.trim(),
          },
        })
        logger.info("Sign up success.", result)

        if (result.userConfirmed) {
          const user = await Auth.signIn(phoneNumber || "", password)
          logger.info("Sign in success.", user)
          navigate("/app", { replace: true })
        } else {
          navigate("/confirm-sign-up", {
            state: { phoneNumber, password },
          })
        }
      } catch (err) {
        logger.error(err)
        let message = err.message
        if (err.message.includes("phone_number")) {
          message = message.replace("phone_number", "mobile number")
        }
        notify.error(message)
      } finally {
        actions.setSubmitting(false)
      }
    }
  }

  return (
    <PageContainer>
      <LogoContainer>
        <Logo width={380} height={100} />
      </LogoContainer>
      <Wrapper>
        <SignUpContainer>
          <Container component="main">
            <SEO title="Sign up" />
            <FormContainer>
              <DescriptionTypography
                variant="h4"
                className={styles.fontWeightSemiBold}
              >
                Register for Paycheck
              </DescriptionTypography>
              <DescriptionTypography
                variant="subtitle1"
                className={styles.lightGreyText}
              >
                Please enter your credentials in order to Register
              </DescriptionTypography>
              {notifications?.notification?.variant === "error" && (
                <div className="erroText">
                  {notifications?.notification?.message}
                </div>
              )}
              <Box component="div" mt={5} mx={"18%"}>
                <SignUpForm onSubmit={handleSubmit} />
                <SignInContainer container justify="center">
                  <Grid item xs={12} sm={5}>
                    <div className={styles.lightGreyText}>
                      Already have an account?
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Link
                      href="/sign-in"
                      color="secondary"
                      className={styles.fontWeightSemiBold}
                    >
                      {"Login"}
                    </Link>
                  </Grid>
                </SignInContainer>
              </Box>
            </FormContainer>
          </Container>
        </SignUpContainer>
      </Wrapper>
      <CompactFooter />
    </PageContainer>
  )
}

export default SignUpPage
