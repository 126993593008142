import React from "react"
import { Button as ReactButton, withStyles } from "@material-ui/core"
import styles from '../../styles/main.module.css'

const TextButton: React.FC<any> = (props: any) => {
    const {text, className} = props

    let classNames = `${styles.button}`
    if ( className ) {
        classNames += ` ${className}`
    }

    const Button = withStyles({
        root: {
            "&.Mui-disabled": {
                background: "#3384B5"
            }
        }
    })(ReactButton);

    return (
        <Button {...props} className={classNames}>
            {text}
        </Button>
    )
}

export default TextButton