import React from "react"
import styled from "styled-components"
import EmailIcon from "../images/email-icon.svg"
import PhoneIcon from "../images/phone-icon.svg"
import {
  Container,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core"

const FooterContainer = styled(Container)`
  background-color: #ffffff;
  padding: 50px;
  margin-top:auto;

  @media (max-width: 960px) {
    padding: 20px;
  }
`

const GridContainer = styled(Grid)`
  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
`

const InnerGrid = styled(Grid)`
  @media (max-width: 960px) {
    justify-content: center;
  }
`

const RightsText = styled(Typography)`
  color: ##05497e;
  font-size: 10pt;

  @media (max-width: 960px) {
    border-top: 1px solid #A1A6BD;
    text-align:center;
  }
`

const ContactInfo = styled(Typography)`
  font-size: 14px;
  word-break: keep-all;
  @media (max-width: 1240px) {
    font-size: 10px
  }
`

const ContactIcon = styled(Avatar)`
  width: 30px;
  height: 30px;
  @media (max-width: 1240px) {
    width:20px;
    height:20px;
  }
`

const ContactGrid = styled(Grid)`
  padding: 10px!important;
  justify-content: flex-start
  @media (max-width: 1240px) {
    padding: 0 10px!important;
  }
`

const CompactFooter: React.FC = () => {
  return (
    <FooterContainer maxWidth={false}>
      <GridContainer container spacing={3}>
        <Grid item xs={12} sm={12} md={7} xl={8}>
          <RightsText>© 2020 Paycheck | All rights reserved</RightsText>
        </Grid>
        <Grid container xs={12} sm={12} md={5} xl={4} justify="space-around">
          <InnerGrid
            container
            item
            xs={6}
            sm={6}
            md={6}
            spacing={6}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item xs={1} justify="flex-end">
              <ContactIcon alt="email" src={EmailIcon} />
            </Grid>
            <ContactGrid item xs={6}>
              <ContactInfo>info@paycheck.co.za</ContactInfo>
            </ContactGrid>
          </InnerGrid>

          <InnerGrid
            container
            item
            xs={6}
            sm={6}
            md={6}
            spacing={6}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item xs={1} justify="flex-end">
              <ContactIcon alt="phone" src={PhoneIcon} />
            </Grid>
            <ContactGrid item xs={6}>
              <ContactInfo>+27 87 654 7686</ContactInfo>
            </ContactGrid>
          </InnerGrid>
        </Grid>
      </GridContainer>
    </FooterContainer>
  )
}

export default CompactFooter
