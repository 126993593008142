import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Gilroy",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#205791",
    },
    secondary:{
      main: "#46BDE3",
      contrastText: "#ffffff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#292a2b",
      secondary: "#46BDE3",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: "1px",
        padding: "15px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "12pt",
        width: "200px;",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      text: {
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "12pt",
      },
    },
  },
})

export default theme
