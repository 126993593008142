import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Hidden } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import MenuIcon from "@material-ui/icons/Menu"
import { Link as RouterLink } from "@reach/router"

const StyledBar = styled(AppBar)`
  background-color: #fff;
  box-shadow: none;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: padding 300ms;
  color: black;

  &.scrolled {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .MuiAppBar-colorPrimary {
    color: "black";
    background-color: "#fff";
  }

`

const Logo = styled.img`
  max-width: 200px;
  position: relative;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
`

const SignIn = styled(Button) <any>`
  font-size: 12pt;
  border-radius: 50px;
  text-transform: none;
  background-color: #bedfec;
  box-shadow: none;
  color: #205791;
  padding: 5px 20px;
  font-weight: normal;
  height: 40px;
  width: 170px;
  margin-top: 7px;

  &:hover {
    box-shadow: none;
    background-color: #b2d0db;
  }

  @media (max-width: 960px) {
    display: flex;
    margin: auto;
    margin-top: 10px;
  }
`

const LinkContainer = styled(Container)`
  display: flex;
  column-gap: 40px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

const NavLinkText = styled(Typography)`
  padding-top: 15px;

  @media (min-width: 961px) {
    &.push {
      margin-left: auto;
    }
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }
`

const Hamburger = styled(IconButton)`
  color: #131523;
  align-self: flex-start;
`

const NavLinks = styled(Container)`
  display: contents;
  transition: all 0.5s ease-in-out;

  @media (max-width: 960px) {
    max-height: 0px;
    display: block;
    overflow: hidden;
    opacity: 0;

    &.active {
      max-height: 1000px;
      overflow: visible;
      opacity: 1;
    }
  }
`

const Header: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const frameId = useRef<number>(0)
  const [menuActive, setMenuActive] = useState(false)

  useEffect(() => {
    const checkPosition = (): void => {
      if (containerRef.current) {
        if (window.pageYOffset > 100) {
          containerRef.current.classList.add("scrolled")
        } else {
          containerRef.current.classList.remove("scrolled")
        }
      }
    }

    const onScroll = (): void => {
      frameId.current = window.requestAnimationFrame(checkPosition)
      setMenuActive(false)
    }

    window.addEventListener("scroll", onScroll)

    return (): void => {
      window.removeEventListener("scroll", onScroll)
      window.cancelAnimationFrame(frameId.current)
    }
  }, [])

  return (
    <StyledBar ref={containerRef}>
      <Toolbar>
        <LinkContainer disableGutters={true} maxWidth={false}>
          <Link component={RouterLink} to="/">
            <Logo
              src={require("../images/logos/paycheck-logo.png")}
              alt="Paycheck logo"
            />
          </Link>
          <NavLinks
            disableGutters={true}
            maxWidth={false}
            className={menuActive ? "active" : ""}
          >
            <NavLinkText align="center" color="textPrimary">
              <Link color="inherit" component={RouterLink} to="/#why-paycheck">
                Why Paycheck
              </Link>
            </NavLinkText>
            <NavLinkText align="center" color="textPrimary">
              <Link
                color="inherit"
                component={RouterLink}
                to="/#the-difference"
              >
                The Difference
              </Link>
            </NavLinkText>
            <NavLinkText align="center" color="textPrimary">
              <Link
                color="inherit"
                component={RouterLink}
                to="/#refer-my-employer"
              >
                Refer my Employer
              </Link>
            </NavLinkText>
            <NavLinkText align="center" color="textPrimary" className="push">
              <Link color="inherit" component={RouterLink} to="/contact">
                Contact us
              </Link>
            </NavLinkText>
            <SignIn
              component={RouterLink}
              to="/sign-in"
              variant="contained"
              startIcon={<LockOutlinedIcon />}
            >
              Account&nbsp;<b>sign&nbsp;in</b>
            </SignIn>
          </NavLinks>
        </LinkContainer>
        <Hidden mdUp>
          <Hamburger
            edge="end"
            aria-label="menu"
            onClick={(): void => {
              setMenuActive(!menuActive)
            }}
          >
            <MenuIcon />
          </Hamburger>
        </Hidden>
      </Toolbar>
    </StyledBar>
  )
}

export default Header
