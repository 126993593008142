import React from "react"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import "./SalaryAdvanceTable.css"
import {formatAmount} from "../../../utils/format"
import styles from "../../../styles/main.module.css"

interface Props {
  advances: any[]
}

const SalaryAdvanceTable: React.FC<Props> = ({ advances }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead className={"tableHead"}>
          <TableRow>
            <TableCell>Reference Number</TableCell>
            <TableCell>Paycheck Date</TableCell>
            <TableCell>Earned Amount</TableCell>
            <TableCell>Accessible Amount</TableCell>
            <TableCell>Paycheck Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {advances.length ? advances.map((advance: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{advance.refNumber}</TableCell>
              <TableCell>{advance.advanceDate}</TableCell>
              <TableCell>{"R " + formatAmount(advance.earnedAmount)}</TableCell>
              <TableCell>{"R " + formatAmount(advance.accessibleAmount)}</TableCell>
              <TableCell>{"R " + formatAmount(advance.advanceAmount)}</TableCell>
              <TableCell color="primary">
                <div className={advance.status}>{(advance.status).toUpperCase()}</div>
              </TableCell>
            </TableRow>
          )) : 
            <TableRow>
                <TableCell className={styles.greyBg} colSpan={6}>
                  <div className={`${styles.greyBg} ${styles.darkGreyText} ${styles.textCentered}`}>
                    You have not yet made any Paycheck withdrawal
                  </div>
                </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SalaryAdvanceTable
