import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
    width?: number | string
    height?: number | string
  }
  
const useStyles = makeStyles({
    a: (props: Props) => ({
      width: props.width || "22px",
      height: props.height || "100%",
      verticalAlign: "middle",
    }),
    b: {
        fill: "#46bde3"
    },
    c: {
        fill: "none"
    },
    d: {
        fill: "#fff"
    }
})

const Person: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props)

    return (
        <svg className={classes.a} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <g transform="translate(-370 -167.679)">
                <circle className={classes.b} cx="15" cy="15" r="15" transform="translate(370 167.679)"/>
                <g transform="translate(376.615 174.294)">
                    <path className={classes.c} d="M0,0H16.769V16.769H0Z"/>
                    <path className={classes.d} d="M9.275,2A6.277,6.277,0,0,0,7.664,14.344l1.611,1.612,1.611-1.612A6.277,6.277,0,0,0,9.275,2Zm0,1.4A2.093,2.093,0,1,1,7.183,5.489,2.09,2.09,0,0,1,9.275,3.4Zm0,9.979a5.019,5.019,0,0,1-4.183-2.247C5.113,9.739,7.88,8.978,9.275,8.978s4.162.761,4.183,2.149A5.019,5.019,0,0,1,9.275,13.374Z" transform="translate(-0.89 -0.593)"/>
                </g>
            </g>
        </svg>
    )
}
  
  export default Person