import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: auto;
  width: 95%;
  padding: 1rem;
`

const ListItem = styled.li`
  padding: 0.25rem 0;
`

const SubListItem = styled.li`
  padding: 0.25rem 0;
  width: 85%;
`

const SubListItemHeading = styled.div`
  margin: 0.5rem 0;
  font-weight: bold
`
interface DisclaimerProps {}

const Disclaimer: React.FunctionComponent<DisclaimerProps> = (props) => {
  return (
    <Wrapper>
      <ol>
        <ListItem key={1}>
          Thank you for expressing interest in the services offered by WDAS
          Technologies (Pty) Ltd (Registration Number: 2020/523708/07)
          (“we/us/our/PayCheck”) on behalf of your employer.
        </ListItem>
        <ListItem key={2}>
          Your employer is aware of the fact that employees are frequently faced
          with financial emergencies and that they may require access to a
          portion of their already earned salary or wage prior to payday.
        </ListItem>
        <ListItem key={3}>
          Your employer wishes to assist you by offering, to afford you access
          to a portion of your already earned salary or wage prior to the
          traditional monthly payment date by making available the PayCheck
          Platform to you.
        </ListItem>
        <ListItem key={4}>
          For these purposes your Employer has the right to instruct the
          PayCheck Platform on how to implement and manage such payments and can
          prescribe and /or limit such payments. It is in the Employers sole
          discretion to provide or cancel this service and PayCheck acts on
          their instruction. You therefore accept and understand that an early
          payment of your already earned salary creates a temporary change in
          your employment conditions relating only to agreed normal salary pay
          date.
        </ListItem>
        <ListItem key={5}>
          In these terms and conditions, the words “you” and “your” refer to the
          person accessing the services or gaining access to payment through the
          conduit of the PayCheck Platform.
        </ListItem>
        <ListItem key={6}>
          These terms and conditions contain the contractual terms in relation
          to your engagement with us as pertains to the services to you provided
          by us, at the instance of your employer, through the PayCheck
          Platform.
        </ListItem>
        <ListItem key={7}>
          In order to utilise the services on the PayCheck Platform, you are
          required to acknowledge and accept these terms and conditions. In
          doing so, you are agreeing to bind yourself to these terms and
          conditions.
        </ListItem>
        <ListItem key={8}>
          In order to render the services, we require certain personal
          information. As such and by way of accepting these terms and
          conditions, you consent to PayCheck processing your personal
          information (as described in the Protection of Personal Information
          Act, No. 4 of 2013) as relates to inter alia your employment
          particulars, contact, remuneration and banking details in order for
          you to benefit from the utilization of the PayCheck Platform.
        </ListItem>
        <ListItem key={9}>
          You further agree that we may use your personal information in
          marketing campaigns which, we think, are relevant to you based on your
          information and past transactions
        </ListItem>
        <ListItem key={10}>
          We however confirm that we will process your personal information in
          strict accordance with the terms of the Protection of Personal
          Information Act, No. 4 of 2013 and Electronic Communications and
          Transactions Act, No. 25 of 2002.
        </ListItem>
        <ListItem key={11}>
          The granting of any request rendered by you to us to access any
          portion of your already earned salary or wage prior to the traditional
          monthly payment date is within the ambit of our sole, absolute and
          unfettered discretion
        </ListItem>
        <ListItem key={12}>
          We reserve the right to enquire as to why you require access to your
          already earned salary or wage prior to the traditional monthly payment
          date and may decline any request without being required to advance any
          reasons therefore.
        </ListItem>
        <ListItem key={13}>
          By rendering any request to access any portion of your already earned
          salary or wage prior to the traditional monthly payment date and
          accepting receipt thereof, you authorise your employer to deduct (and
          make payment to us of) an amount equal to the amount made available to
          you by us at your instance PLUS any transaction cost you’ve agreed to
          during your application process, which includes a percentage-based
          transaction fee and/or a fixed fee. Until such date as the employer
          has made such deduction, the amount will be deemed to be owed by you
          to us.
        </ListItem>
        <ListItem key={14}>
          If for any reason there is a shortfall on the agreed payment terms,
          you agree that the full outstanding amount together with a reasonable
          administration fee, may be deducted from any payments due to you in
          the future by your employer
        </ListItem>
        <ListItem key={15}>
          You hereby consent to any outstanding amount due to PayCheck will be
          deducted from your final payment upon termination of your employment
          with your employer.
        </ListItem>
        <ListItem key={16}>
          The amount made available to you through the PayCheck Platform will
          always be paid into the same Bank Account or Celbux Wallet into which
          your salary is paid by your employer from time to time.
        </ListItem>
        <ListItem key={17}>
          NO third-party payments will be made by PayCheck
        </ListItem>
        <ListItem key={18}>
          You have the right to dispute any transaction. However, you can only
          raise a dispute after the full amount outstanding has been paid by
          your employer
        </ListItem>
        <ListItem key={19}>
          You choose as your address for service of legal processes and notices
          the addresses as indicated on your personal information received from
          your employer.
        </ListItem>
        <ListItem key={20}>
          You acknowledge that you have had the opportunity to obtain
          independent legal advice before agreeing to be bound to these terms
          and conditions and in the absence of having done so, that you have
          waived your right to do so.
        </ListItem>
        <ListItem key={21}>
          You record that English is your preferred language that you use to
          conduct business in, and that you have read these terms and conditions
          and fully understand the content, meaning and implications thereof.
        </ListItem>
        <ListItem key={22}>
          These terms and conditions constitute the whole agreement between the
          parties with regard to its subject matter.
        </ListItem>
        <ListItem key={23}>
          We reserve the rights to change, modify, add or remove from portions
          or the whole of these terms and conditions from time to time. Changes
          to these terms and conditions will become effective upon such changes
          being posted to our website provided that the terms and conditions
          applicable to past or current transactions, already entered into, may
          only be amended by agreement between the parties in writing.
        </ListItem>
        <ListItem key={24}>
          No indulgence granted by us, or failure to strictly enforce these
          terms and conditions, shall be construed as a waiver of our rights nor
          shall it be capable of founding estoppel.
        </ListItem>
        <ListItem key={25}>
          Save as is specifically provided for in these terms and conditions,
          neither party shall be entitled to cede any of its rights or delegate
          any of its obligations under these terms and conditions without the
          prior written consent of the other party
        </ListItem>
        <ListItem key={26}>
          If any provision of these terms and conditions is rendered void,
          illegal or unenforceable in any respect under any law, the validity,
          legality and enforceability of the remaining provisions shall not in
          any way be affected or impaired thereby.
        </ListItem>
        <ListItem key={27}>
          <b>
            In compliance with section 43 of the Electronic Communications And
            Transactions Act, No. 25 of 2002, we draw your attention to the
            following information;
          </b>
          <br />
          <ol>
            <SubListItem key={27.1} value="1">
              <SubListItemHeading>
                Full name and legal status
              </SubListItemHeading>
              <b>Name:</b> WDAS Technologies (Pty) Ltd (Registration Number:
              2020/523708/07)
              <br />
              <b>Status:</b> Private company in business
            </SubListItem>
            <SubListItem key={27.2} value="2">
              <SubListItemHeading>
                Physical address and telephone number
              </SubListItemHeading>
              30 Alexander Road, Shere, Pretoria, 0084
              <br />
              Telephone Number: +27 87 654 7686
            </SubListItem>
            <SubListItem key={27.3} value="3">
              <SubListItemHeading>Web site address and e-mail address</SubListItemHeading>
              Website: www.paycheck.co.za
              <br />
              E-mail: info@paycheck.co.za
            </SubListItem>
            <SubListItem key={27.4} value="4">
              <SubListItemHeading>
                Membership of any self-regulatory or accreditation bodies to
                which that supplier belongs or subscribes and the contact
                details of that body
              </SubListItemHeading>
              N/A
            </SubListItem>
            <SubListItem key={27.5} value="5">
              <SubListItemHeading>
                Any code of conduct to which that supplier subscribes and how
                that code of conduct may be accessed electronically by the
                consumer
              </SubListItemHeading>
              N/A
            </SubListItem>
            <SubListItem key={27.6} value="6">
              <SubListItemHeading>
                In the case of a legal person, its registration number, the
                names of its office bearers and its place of registration;
              </SubListItemHeading>
              <b>Name and Registration Number:</b> Refer to clause 27.1
              <br />
              <b>Place of registration:</b>Refer to clause 27.2
              <br />
              <b>Office Bearer: </b>Matthys Gerhardus Liebenberg
            </SubListItem>
            <SubListItem key={27.7} value="7">
              <SubListItemHeading>
                The physical address where that supplier will receive legal
                service of documents;
              </SubListItemHeading>
              Please refer to clause 27.2
            </SubListItem>
            <SubListItem key={27.8} value="8">
              <SubListItemHeading>
                A sufficient description of the main characteristics of the
                goods or services offered
              </SubListItemHeading>
              Please refer to the terms and conditions relating to the services
              in question.
            </SubListItem>
            <SubListItem key={27.9} value="9">
              <SubListItemHeading>The full price of the goods or services </SubListItemHeading>
              Please refer to our terms and conditions above relating to the
              services in question.
            </SubListItem>
            <SubListItem key={27.1} value="10">
              <SubListItemHeading>The manner of payment</SubListItemHeading>
              Please refer to clause 12
            </SubListItem>
            <SubListItem key={27.11} value="11">
              <SubListItemHeading>
                Any terms of agreement, including any guarantees, that will
                apply to the transaction and how those terms may be accessed,
                stored and reproduced electronically by consumers
              </SubListItemHeading>
              N/A
            </SubListItem>
            <SubListItem key={27.12} value="12">
              <SubListItemHeading>
                The time within which the goods will be dispatched or delivered
                or within which the services will be rendered;
              </SubListItemHeading>
              Please refer to our proposal relating to the services in question.
            </SubListItem>
            <SubListItem key={27.13} value="13">
              <SubListItemHeading>
                The manner and period within which the Customer can access and
                maintain a full record of the transaction
              </SubListItemHeading>
              You will be required to contact the us directly to access any
              transaction documentation.
            </SubListItem>
            <SubListItem key={27.14} value="14">
              <SubListItemHeading>The return, exchange and refund policy</SubListItemHeading>
              No refunds, exchanges or returns will be permitted in respect of
              the services under any circumstances other than those provided for
              in these terms and conditions
            </SubListItem>
            <SubListItem key={27.15} value="15">
              <SubListItemHeading>
                Any alternative dispute resolution code to, which that supplier
                subscribes and how the wording of that code may be accessed
                electronically by the consumer
              </SubListItemHeading>
              N/A
            </SubListItem>
            <SubListItem key={27.16} value="16">
              <SubListItemHeading>
                The security procedures and privacy policy in respect of
                payment, payment information and personal information
              </SubListItemHeading>
              Please refer to our Privacy Policy.
            </SubListItem>
            <SubListItem key={27.17} value="17">
              <SubListItemHeading>
                where appropriate, the minimum duration of the agreement in the
                case of agreements for the supply of products or services to be
                performed on an ongoing basis or recurrently;
              </SubListItemHeading>
              N/A
            </SubListItem>
          </ol>
        </ListItem>
        <ListItem key={28}>
          You can contact us directly on info@paycheck.co.za or call 087 654
          7676 with any questions
        </ListItem>
      </ol>
    </Wrapper>
  )
}

export default Disclaimer
