import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
    width?: number | string
    height?: number | string
}
  
  export const useStyles = makeStyles({
    a: (props: Props) => ({
      width: props.width || "22px",
      height: props.height || "100%",
      verticalAlign: "middle",
    }),
    b: {
        fill: "#46bde3"
    },
    c: {
        fill: "#fff"
    }
  })

const BankCardCheck: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes.a} viewBox="0 0 30 30">
            <g transform="translate(-1360 -184)">
                <g transform="translate(1360 184)">
                    <circle className={classes.b} cx="15" cy="15" r="15"/>
                </g>
                <g transform="translate(1368.383 193.631)">
                    <path className={classes.c} d="M14.949,4H3.439A1.438,1.438,0,0,0,2.007,5.46L2,14.219a1.444,1.444,0,0,0,1.439,1.46h3.6v-1.46h-3.6V9.84H16.388V5.46A1.444,1.444,0,0,0,14.949,4Zm0,2.92H3.439V5.46h11.51ZM11.3,15.073,9.266,13.008,8.252,14.037l3.05,3.1,5.086-5.161-1.014-1.029Z" transform="translate(-2 -4)"/>
                </g>
            </g>
        </svg>
    )
}
  
  export default BankCardCheck