import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
    width?: number | string
    height?: number | string
  }
  
  export const useStyles = makeStyles({
    a: (props: Props) => ({
      width: props.width || "22px",
      height: props.height || "100%",
      verticalAlign: "middle",
    }),
    b: {
        fill: "#46bde3"
    },
    c: {
        fill: "none"
    },
    d: {
        fill: "#fff"
    }
  })

const BankCard: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes.a} viewBox="0 0 30 30">
            <g transform="translate(-990 -166.679)">
                <circle className={classes.b} cx="15" cy="15" r="15" transform="translate(990 166.679)"/>
                <g transform="translate(996.336 173.015)">
                    <path className={classes.c} d="M0,0H17.248V17.248H0Z"/>
                    <path className={classes.d} d="M14.924,4H3.436A1.424,1.424,0,0,0,2.007,5.434L2,14.039a1.43,1.43,0,0,0,1.436,1.434H14.924a1.43,1.43,0,0,0,1.436-1.434v-8.6A1.43,1.43,0,0,0,14.924,4Zm0,10.039H3.436v-4.3H14.924Zm0-7.17H3.436V5.434H14.924Z" transform="translate(-0.556 -1.112)"/>
                </g>
            </g>
        </svg>
    )
}
  
  export default BankCard