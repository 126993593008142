import { Box, Divider, Grid, TableCell, TableRow, createStyles, makeStyles, Theme } from "@material-ui/core"
import React from "react"
import styles from "../../../styles/main.module.css"
import { formatAmount } from "../../../utils/format"


interface Props {
  advances: any[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      padding: "4px 0px",
    },
    itemHeading: {
      fontWeight: "bold",
      fontSize: "0.8rem"
    },
    itemData: {
      color: "#A1A6BD",
      fontSize: "0.7rem"
    },
  }),
)

const AdvanceHistoryMobileView: React.FC<Props> = ({ advances }: Props) => {

  let classes = useStyles()
  return (
    <Grid sm={12} xs={12}>
      {advances.length ? (
        advances.map((advance: any, index: number) => (
          <Box>
            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Reference Number</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                {advance.refNumber}
              </Grid>
            </Grid>
            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Paycheck Date</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                {advance.advanceDate}
              </Grid>
            </Grid>
            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Earned Amount</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                {"R " + formatAmount(advance.earnedAmount)}
              </Grid>
            </Grid>

            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Accessible Amount</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                {"R " + formatAmount(advance.accessibleAmount)}
              </Grid>
            </Grid>

            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Paycheck Amount</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                {"R " + formatAmount(advance.advanceAmount)}
              </Grid>
            </Grid>

            <Grid container className={classes.itemContainer}>
              <Grid item xs={7} className={classes.itemHeading}>
                <label>Status</label>
              </Grid>
              <Grid item xs={5} className={classes.itemData}>
                <div className={advance.status}>
                  {advance.status.toUpperCase()}
                </div>
              </Grid>
            </Grid>
            <Divider orientation="horizontal" />
          </Box>
        ))
      ) : (
        <TableRow>
          <TableCell className={styles.greyBg} colSpan={6}>
            <div
              className={`${styles.greyBg} ${styles.darkGreyText} ${styles.textCentered}`}
            >
              You have not yet made any Paycheck withdrawal
            </div>
          </TableCell>
        </TableRow>
      )}
    </Grid>
  )
}

export default AdvanceHistoryMobileView
