import * as React from "react"

import { SEO } from "."
import Dashboard from "./dashboard"
import AppContainer from "./AppContainer"

const TITLE = "Welcome To Your Paycheck"

const DashboardPage: React.FC<{}> = () => (
  <div>
    <SEO title={TITLE} />
    <AppContainer title={TITLE}>
      <Dashboard />
    </AppContainer>
  </div>
)

export default DashboardPage
