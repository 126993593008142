import React from "react"
import styled from "styled-components"

import { Container, Grid, Link, Typography } from "@material-ui/core"
import { Link as RouterLink } from "@reach/router"

const FooterContainer = styled(Container)`
  background-color: #0d2d4e;
  padding: 60px 80px;
  padding-bottom: 10px;
`

const Logo = styled.img`
  width: 200px;
  position: relative;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
`

const SmallLogo = styled.img`
  height: 20px;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
`

const RightsText = styled(Typography)`
  float: right;
  color: #fff;
  font-size: 10pt;
`

const Heading = styled(Typography)`
  color: #fff;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 10px;
`

const LinkTexts = styled(Typography)`
  line-height: 2;
`

const LinkItem = styled(Link)<any>`
  color: #75dfef;

  &:hover {
    cursor: pointer;
  }
`

const LegalGrid = styled(Grid)`
  margin-top: 200px;
`

const Footer: React.FC = () => {
  return (
    <FooterContainer maxWidth={false}>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <Link
            onClick={(): void => {
              window.scrollTo({ top: 0, behavior: "smooth" })
            }}
          >
            <Logo
              src={require("../images/logos/footer-logo.png")}
              alt="Paycheck logo"
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Heading>Why Paycheck?</Heading>
          <LinkTexts>
            <LinkItem component={RouterLink} to="/#why-paycheck">
              Benefits
            </LinkItem>
            <br />
            <LinkItem component={RouterLink} to="/#the-difference">
              The Difference
            </LinkItem>
            <br />
            <LinkItem component={RouterLink} to="/sign-up">
              Employer sign up
            </LinkItem>
            <br />
            <LinkItem component={RouterLink} to="/sign-in">
              Employer sign in
            </LinkItem>
          </LinkTexts>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Heading>Company</Heading>
          <LinkTexts>
            <LinkItem>FAQs</LinkItem>
            <br />
            <LinkItem>About us</LinkItem>
            <br />
            <LinkItem component={RouterLink} to="/contact">
              Contact us
            </LinkItem>
          </LinkTexts>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Heading>Legal</Heading>
          <LinkTexts>
            <LinkItem>Terms of use</LinkItem>
            <br />
            <LinkItem>Privacy policy</LinkItem>
          </LinkTexts>
        </Grid>
      </Grid>
      <LegalGrid container>
        <Grid item xs={12} sm={7} md={6}>
          <RightsText>© 2020 Paycheck | All rights reserved</RightsText>
        </Grid>
      </LegalGrid>
    </FooterContainer>
  )
}

export default Footer
