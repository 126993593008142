import { navigate } from "gatsby"
import React from "react"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import BalanceWallet from "./icons/BalanceWallet"

const useStyles = makeStyles({
  ListItem: {
    borderRight: "3px solid #47bde3",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  listItemIcon: {
    minWidth: "initial",
    paddingRight: "10px",
  },
  listItemText: {
    color: "#024A7E",
    fontSize: "0.8rem",
  },
})
interface IDrawerListProps {
  hidden: boolean
}

const DrawerList: React.FC<IDrawerListProps> = ({hidden}:IDrawerListProps) => {
  const classes = useStyles()

  return (
    <List>
      <ListItem
        className={classes.ListItem}
        button
        onClick={(): Promise<void> => navigate("/app")}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <BalanceWallet />
        </ListItemIcon>
          <ListItemText hidden={hidden}
            classes={{ primary: classes.listItemText }}
            primary="Your Paycheck"
          />
      </ListItem>
    </List>
  )
}

export default DrawerList
