import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import React from "react"
import { isMobile } from "react-device-detect"
import styles from "../../styles/main.module.css"
import { formatAmount } from "../../utils/format"
import TextButton from "../elements/TextButton"
import BalanceWallet from "../icons/BalanceWallet"
import TransactionSideBar from "./TransactionSideBar"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px 0px",
    },
    amountSpan: {
      padding: "0 8px",
      fontSize: isMobile ? "1.8rem" : "2.2rem",
    },
    amountHeader: {
      fontWeight: "normal",
      marginTop: "-15px",
      marginBottom: "5px",
    },
    amountInfoSpan: {
      fontSize: isMobile ? "0.7rem" : "0.8rem",
    },
    amountFooter: {},
    amountBody: {
      marginBottom: "20px",
    },
    applyForWithdrawal: {
      borderRadius: "8px",
      cursor: "pointer",
    },
    applyForWithdrawalButton: {
      color: "#fff",
      padding: "0 15px!important",
      textAlign: "left",
      margin: isMobile ? "4px" : "20px -15px 0px -15px!important",
      lineHeight: "1.2",
    },
    closeButton: {
      borderRadius: "50%",
      background: "#fff",
      width: "initial",
      minWidth: "initial",
      padding: "6px",
      float: "right",
    },
    mobileApplyForWithdrawalButton: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      right: 0,
      bottom: 0,
      height: "10%",
      width: "100%",
      zIndex: 1200,
      overflowY: "auto",
    },
  }),
)

const EarningsAndFunds: React.FC<any> = ({
  employee,
  refetch,
  allowPaycheck,
}: any) => {
  const classes = useStyles()
  const [openTransationSideBar, setOpenTransationSideBar] = React.useState(
    false,
  )

  const handleTransactionClose = () => {
    setOpenTransationSideBar(false)
  }

  return (
    <Box
      component={isMobile ? Paper : Grid}
      display="flex"
      flexWrap="wrap"
      width="100%"
      boxSizing="border-box"
      p={1}
      className={classes.root}
    >
      <Grid item sm={4}>
        <div className={styles.padding}>
          <h4 className={`${classes.amountHeader}`}>Your Earnings</h4>
          <div className={classes.amountBody}>
            <span className={`${styles.borderLeft} ${classes.amountSpan}`}>
              R{formatAmount(employee.earnedAmount)}
            </span>
          </div>
          <div className={classes.amountFooter}>
            <span className={`${styles.greyText} ${classes.amountInfoSpan}`}>
              Determined by the date, number of work days in this Work cycle.
            </span>
          </div>
        </div>
      </Grid>
      <Grid item sm={4}>
        <div className={styles.padding}>
          <h4 className={`${classes.amountHeader} ${styles.darkBlueText}`}>
            Funds Available today
          </h4>
          <div className={classes.amountBody}>
            <span
              className={`${styles.borderLeft} ${classes.amountSpan} ${styles.darkBlueText} ${styles.boldText}`}
            >
              R{formatAmount(employee.accessibleAmount)}
            </span>
          </div>
          <div className={classes.amountFooter}>
            <span
              className={`${styles.darkBlueText} ${classes.amountInfoSpan}`}
            >
              Based on the terms and conditions set by your employer.
            </span>
          </div>
        </div>
      </Grid>
      <Grid item sm={4} hidden={isMobile}>
        <div
          className={`${styles.padding} ${styles.blueBg} ${classes.applyForWithdrawal}`}
          onClick={() => {
            setOpenTransationSideBar(true)
          }}
          hidden={!allowPaycheck}
        >
          <BalanceWallet color="#fff" />
          <br />
          <TextButton
            text="Apply for Paycheck Withdrawal"
            endIcon={<ChevronRightIcon />}
            className={classes.applyForWithdrawalButton}
          />
        </div>
      </Grid>
      <Grid item sm={12} hidden={!isMobile}>
        <div
          className={`${styles.padding} ${styles.blueBg} ${classes.mobileApplyForWithdrawalButton}`}
          onClick={() => {
            setOpenTransationSideBar(true)
          }}
          hidden={!allowPaycheck}
        >
          <BalanceWallet color="#fff" />

          <TextButton
            text="Apply for Paycheck Withdrawal"
            endIcon={<ChevronRightIcon />}
            className={classes.applyForWithdrawalButton}
          />
        </div>
      </Grid>
      <TransactionSideBar
        employeeId={employee.id}
        celbuxNumber={employee.celbuxMobileNumber}
        accessibleAmount={employee.accessibleAmount}
        onClose={handleTransactionClose}
        open={openTransationSideBar}
        refetch={refetch}
      />
    </Box>
  )
}

export default EarningsAndFunds
