/* eslint-disable @typescript-eslint/camelcase */
import { Auth, Logger } from "aws-amplify"
import { FormikHelpers } from "formik"
import { navigate } from "gatsby"
import React, { useMemo } from "react"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import { useNotify } from "../../notifications"
import ConfirmSignUpForm, { FormValues } from "./ConfirmSignUpForm"
import FormContainer from "./FormContainer"
import { Logo, SEO } from ".."
import styled from "styled-components"
import CompactFooter from "../CompactFooter"
import styles from "../../styles/main.module.css"
import { isMobile } from "react-device-detect"

const PageContainer = styled.div`
  background-color: #f7f9fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

const SignInContainer = styled.div`
  padding: 1rem 0;
  width: 700px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  @media (max-width: 960px) {
    padding: 120px 50px 0px 50px;
  }

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 5rem;
  @media (max-width: 600px) {
    padding: 0;
    width: 90%;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
`

const LogoContainer = styled(Container)`
  padding: 45px;
  text-align: center;

  @media (max-width: 1240px) {
    padding: 60px 10px 52px 10px;
    padding: 30px;
  }

  @media (max-width: 1024px) {
    padding: 60px 10px 52px 10px;
  }

  @media (max-width: 600px) {
    img {
      max-width: 280px;
    }
  }

  @media (max-width: 300px) {
    img {
      max-width: 180px;
    }
  }
`

const SignUpContainer = styled(Grid)`
  text-align: center;
`

const DescriptionTypography = styled(Typography)`
  word-break: word;
  text-align: center;
`

interface Props {
  location: any
}

const ConfirmSignUpPage: React.FC<Props> = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(ConfirmSignUpPage.name), [])
  const notify = useNotify()

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const { code } = values
    const { phoneNumber, password } = location.state
    try {
      const result = await Auth.confirmSignUp(phoneNumber, code, password)
      logger.info(`SignUp confirmed.`, result)
      notify.success("Account confirmed, please sign to continue.")
      navigate("/sign-in", { replace: true })
    } catch (err) {
      logger.error(err)
      notify.error(err.message)
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <PageContainer>
      <LogoContainer>
        <Logo width={380} height={100} />
      </LogoContainer>
      <Wrapper>
        <SignInContainer>
          <Container component="main">
            <SEO title="Confirm account" />
            <FormContainer>
              <DescriptionTypography
                variant="h4"
                className={styles.fontWeightSemiBold}
              >
                Confirm your account
              </DescriptionTypography>
              <DescriptionTypography
                variant="subtitle1"
                className={styles.lightGreyText}
              >
                Please enter verification code
              </DescriptionTypography>
              <Box component="div" mt={1} mx={"auto"} style={{width: isMobile ? "100%" : "60%"}}>
                {<ConfirmSignUpForm onSubmit={handleSubmit} />}

                <SignUpContainer container justify="center">
                  <Grid item xs={12}>
                    <Link href="/sign-in" color="secondary">
                      {"Didn't get the verification code? Try again."}
                    </Link>
                  </Grid>
                </SignUpContainer>
              </Box>
            </FormContainer>
          </Container>
        </SignInContainer>
      </Wrapper>
      <CompactFooter />
    </PageContainer>
  )
}

export default ConfirmSignUpPage
