import React from "react"
import { Grid, Divider, Paper } from "@material-ui/core"
import BankCard from "../icons/BankCard"
import BriefCase from "../icons/BriefCase"
import Person from "../icons/Person"
import styles from "../../styles/main.module.css"
import { payCycles } from "../../utils/payCycle"
interface Props {
  employee: any
}

const EmployeeSummary: React.FC<Props> = ({ employee }: Props) => {
  const paycycle = employee.payCycle as string
  return (
    <Grid container spacing={1} justify="center" alignItems="stretch">
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={styles.paper} elevation={1}>
          <h3 className={styles.paperHeader}>
            <Person /> Personal Information
          </h3>
          <div className={styles.paperItemWrapper}>
            <span className={styles.paperItemValue}>{`${employee.name ?? ""} ${
              employee.surname ?? ""
            }`}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Name and Surname
            </span>
            <Divider />
            <span className={styles.paperItemValue}>{employee.idNumber}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              ID Number
            </span>
            <Divider />
            <span className={styles.paperItemValue}>{employee.mobile}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Contact Number
            </span>
            <Divider />
            <span className={styles.paperItemValue}>{employee.email}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Email
            </span>
          </div>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={styles.paper} elevation={1}>
          <h3 className={styles.paperHeader}>
            <BriefCase /> Employee Information
          </h3>
          <div className={styles.paperItemWrapper}>
            <span className={styles.paperItemValue}>
              {employee?.employer?.name}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Employer Name
            </span>
            <Divider />
            <span className={styles.paperItemValue}>
              {employee.employeeNumber}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Employee Number
            </span>
            <Divider />
            <span className={styles.paperItemValue}>{payCycles[paycycle]}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Payday Cycle
            </span>
            <Divider />
            <span className={styles.paperItemValue}>
              R {employee.nettSalary ?? 0}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Monthly Nett Pay
            </span>
          </div>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={styles.paper} elevation={1}>
          <h3 className={styles.paperHeader}>
            <BankCard /> Account Details
          </h3>
          <div className={styles.paperItemWrapper}>
            <span className={styles.paperItemValue}>{employee.bankName}</span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Bank
            </span>
            <Divider />
            <span className={styles.paperItemValue}>
              {employee.accountNumber}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Account Number
            </span>
            <Divider />
            <span className={styles.paperItemValue}>
              {employee.accountType == "cheque_account"
                ? "Cheque Account"
                : "Saving Account"}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Account Type
            </span>
            <Divider />
            <span className={styles.paperItemValue}>
              {employee.celbuxMobileNumber}
            </span>
            <span className={`${styles.paperItemName} ${styles.greyText}`}>
              Celbux Number
            </span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default EmployeeSummary
